<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Cambio de Vehículo')}} </h3>
		</div>
		<div class="p-col-12" v-if="!displayeditar">
			<div class="card">
				<DataTable :value="poliza" :scrollable="true" scrollHeight="400px" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                    <template #header>
                        <div class="table-header">
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="buscar" type="number"/>
                            </span>
                            <Button :disabled ="$store.state.loading" :label="$t('Buscar')" icon="pi pi-search" class="p-button-secondary" @click="Buscar"/>
                        </div>
                    </template>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.estado==0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Anulada')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==1">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Inicial')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==2">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Evaluación')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==3">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Compromiso')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==4">
                                <Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff"/>
                                {{$t('Verificación  de Pago')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==6">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Anulada')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==7">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Proceso Renovación')}}
                            </div>
                            <div v-else-if="slotProps.data.vend<0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Vencida')}}
                            </div>
                            <div v-else-if="slotProps.data.pendiente>0">
                                <Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff"/>
                                {{$t('Pago Pendiente')}}
                            </div>
                            <div v-else-if="slotProps.data.proceso>0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Suspendida')}} <br> ({{$t('Pago Vencido')}})
                            </div>
                            <div v-else>
                                <Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff"/>
                                {{$t('Activa')}}
                            </div>
                        </template>
                    </Column>        
                    <Column field="codigo" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.datos.codigo}}<br>
                            {{$t('Activación')}}: {{slotProps.data.datos.activacion}}<br>
                            {{$t('Vencimiento')}}: {{slotProps.data.datos.vence}}
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.datos.cliente}}                              
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre Completo')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.datos.nombre}}                              
                        </template>
                    </Column>
                    <Column field="duracion_name" :header="$t('Duración')" :sortable="true" headerStyle="width: 110px">
                        <template #body="slotProps">
                            <div v-if="i18n.locale() == 'es'">{{slotProps.data.duracion_nombre}}</div>
                            <div v-else>{{slotProps.data.duracion_name}}</div>     
                        </template>
                    </Column>
                    <Column :header="$t('Acciones')" headerStyle="width: 120px">
                        <template #body="slotProps">
                            <Button :disabled ="$store.state.loading" icon="pi pi-check-circle" class="p-button-rounded p-button-secondary p-mr-2" @click="Editar(slotProps.data)" v-if="slotProps.data.estado==5"/>
                            <Button :disabled ="$store.state.loading" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="VerPDF(slotProps.data)" v-if="slotProps.data.datos.cambio"/>
                            <div v-if="slotProps.data.migracion">{{$t('Migración')}}</div>
                            <div v-if="slotProps.data.datos.cambio">{{$t('Cambio de Vehículo')}}</div>
                        </template>
                    </Column>
                </DataTable>
			</div>
        </div>
		<div class="p-col-12" v-else>
			
            <div class="card">
                <h4>{{$t('Información Básica')}}</h4>
                <div class="p-fluid p-grid">
					<div class="p-col-3">
						{{$t('Póliza')+' #'}}: <strong>{{police.datos.codigo}}</strong>			
					</div>
					<div class="p-col-3">
						{{$t('Activación')}}: <strong>{{police.activacion}}</strong>			
					</div>
					<div class="p-col-3">
						{{$t('Vencimiento')}}: <strong>{{police.vence}}</strong>			
					</div>
					<div class="p-col-3">
						{{$t('Cliente')+' #'}}: <strong>{{police.datos.cliente}}</strong>			
					</div>
					<div class="p-col-3">
						{{$t('Fecha')}}: <strong>{{police.fecha}}</strong>			
					</div>
					<div class="p-col-3">
						{{$t('Número ID')}}: <strong>{{police.code}}</strong>			
					</div>
					<div class="p-col-3" v-if="i18n.locale() == 'es' && police.tipocliente==1">
						{{$t('Sexo')}}: <strong>{{police.sexnombre}}</strong> 			
					</div>
					<div class="p-col-3" v-if="i18n.locale() == 'en' && police.tipocliente==1">
						{{$t('Sexo')}}: <strong>{{police.sexname}}</strong> 			
					</div>
					<div class="p-col-6">
						{{$t('Nombre Completo')}}: <strong>{{police.nombre}}</strong>			
					</div>
					<div class="p-col-6">
						{{$t('Correo')}}: <strong>{{police.correo}}</strong>			
					</div>
					<div class="p-col-4" v-if="police.tipocliente==1">
						{{$t('Fecha de nacimiento')}}: <strong>{{police.fechanac}}</strong>			
					</div>
					<div class="p-col-2" v-if="police.tipocliente==1">
						{{$t('Edad')}}: <strong>{{police.edad}}</strong>			
					</div>
					<div class="p-col-6" v-if="i18n.locale() == 'es'">
						{{$t('Nacionalidad')}}: <strong>{{police.nacionalidad}}</strong> 			
					</div>
					<div class="p-col-6" v-else>
						{{$t('Nacionalidad')}}: <strong>{{police.nationality}}</strong> 			
					</div>
					<div class="p-col-4">
						{{$t('Localidad')}}: <strong>{{police.localidadn}}</strong>			
					</div>
					<div class="p-col-4">
						{{$t('Teléfono móvil')}}: <strong>{{police.celular}}</strong>			
					</div>
					<div class="p-col-4">
						{{$t('Teléfono privado')}}: <strong>{{police.tlflocal}}</strong>			
					</div>
				</div>
            </div>
			<div class="card">
				<h4>{{$t('Pagos')}}</h4>
				<div class="p-fluid p-grid">
					<div class="p-col-12">
						<DataTable :value="police.deudas" stripedRows responsiveLayout="scroll">
							<Column :header="$t('Estatus')">
								<template #body="slotProps">
									<Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff" v-if="slotProps.data.pagada"/>
									<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff" v-else/>
									<strong v-if="slotProps.data.pagada">{{$t('Pagado')}}</strong>
									<strong v-else>{{$t('Pendiente')}}</strong>
								</template>
							</Column>
							<Column :header="$t('Fecha')">
								<template #body="slotProps">
									{{slotProps.data.fecha}}                              
								</template>
							</Column> 
							<Column field="factura" :header="$t('Factura')+' #'">
								<template #body="slotProps">
									{{slotProps.data.factura}}                              
								</template>
							</Column>
							<Column :header="$t('Monto')">
								<template #body="slotProps">
									<div v-if="slotProps.data.monto<0" style="color:red;">
										{{moneda(slotProps.data.monto)}}
									</div>
									<div v-else>
										{{moneda(slotProps.data.monto)}}
									</div>
								</template>
							</Column>
						</DataTable>
					</div>
					<div class="p-col-4">
						<h5><strong>{{$t('Pagos Recibidos')}}: {{moneda(anterior.datos.pagos+anterior.datos.proceso)}}</strong></h5>
					</div>
					<div class="p-col-4">
						<h5><strong>{{$t('Pagos Pendientes')}}: {{moneda(anterior.datos.deudas+anterior.datos.pendiente)}}</strong></h5>
					</div>
					<div class="p-col-4">
						<h5><strong>{{$t('Monto Total')}}: {{moneda(anterior.evaluacion.montofinal)}}</strong></h5>
					</div>
				</div>
            </div>
            <div class="card">
                <h4>{{$t('Información del Vehículo')}}</h4>
                <div class="p-fluid p-grid">
					<div class="p-col-3">
						<label for="state">{{$t('Tipo de Vehículo')}}</label><br>
						<Dropdown v-model="police.tipovehiculo" :options="tipos.filter(val => val.tipocliente == police.tipocliente && val.tipopoliza == police.tipopoliza)" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Calcular"></Dropdown>
					</div>
					<div class="p-col-3">
						<label for="state">{{$t('Marca')}}</label><br>
						<Dropdown v-model="police.marca" :options="marcavehiculo" optionLabel="nombre" :placeholder="$t('Seleccione...')" @change="vermodelos()"></Dropdown>
					</div>
					<div class="p-col-3">
						<label for="state">{{$t('Módelo')}}</label><br>
						<Dropdown v-model="police.modelo" :options="modelos" optionLabel="nombre" :placeholder="$t('Seleccione...')" @change="veryears()"></Dropdown>
					</div>
					<div class="p-col-3">
						<label for="state">{{$t('Año de construcción')}}</label><br>
						<Dropdown v-model="police.veh_ano" :options="years" optionLabel="nombre" placeholder="...?" @change="Calcular()"></Dropdown>		
					</div>
					<div class="p-col-2">
						<label>{{$t('Año de Referencia')}}</label>
						<InputMask v-model="police.veh_compra" mask="9999"/>	
					</div>
					<div class="p-col-2">
						<label for="state">{{$t('Automático')}}</label>
                        <Checkbox v-model="police.veh_automatico" :binary="true" />
					</div>
					<div class="p-col-2">
						<label>{{$t('Nº asientos')}}</label>
						<InputNumber v-model="police.veh_asientos" min="1" max="100" integeronly showButtons />
					</div>
					<div class="p-col-3">
						<label>{{$t('Número de chasis')}}</label>
						<InputText v-model="police.veh_chasis" type="text"/>
					</div>
					<div class="p-col-3">
						<label>{{$t('Nº placa')}}</label>
						<InputText v-model="police.veh_placa" type="text"/>
					</div>
					<div class="p-col-3">
						<label>{{$t('Color')}}</label>
						<InputText v-model="police.veh_color" type="text"/>
					</div>
					<div class="p-col-3">
						<label>{{$t('Posición del Volante')}}</label>
						<SelectButton v-model="police.veh_volante" :options="options" @click="Calcular()"/>
					</div>
				</div>
                 <br>
                <h4>Keuringskaart</h4>
                <div class="p-fluid p-grid">
					<div class="p-col-3">
						<label>{{$t('Fecha de emisión')}}</label>
                        <InputMask v-model="police.cardemi" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
					</div>
					<div class="p-col-3">
						{{$t('Registro')}}: <strong>{{police.cardreg}}</strong>
                    <label>{{$t('Validez')}}</label>
						<Dropdown v-model="police.cardval" :options="validez" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
                    </div>
					<div class="p-col-3">
						<FileUpload :chooseLabel="$t('Subir')" uploadLabel="Cargar" mode="basic" :name="img" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader()" :auto="true"/>
					</div>
					<div class="p-col-3">
						<img style="width: 100px;" :src="url.upload+police.veh_adverso" @click="toggle" v-if="police.veh_adverso"/>
					</div>
                </div>
            </div>
            <div class="card">
                <h4>{{$t('Evaluación')}}</h4>
                <h5 v-if="i18n.locale() == 'es'">{{$t('Tipo de Póliza')}}: <strong>{{police.evaluacion.tipopoliza_name}}</strong></h5>
                <h5 v-else>{{$t('Tipo de Póliza')}}: <strong>{{police.evaluacion.tipopoliza_name}}</strong></h5>     
                <div class="p-fluid p-grid">
                    <div class="p-col-12">
                        <Checkbox v-model="police.exoneracion" :binary="true"/> <strong>{{$t('Exoneracion')}}</strong><br><br>
                    </div>
					<div class="p-col-3">
						<label for="state">{{$t('Tarifa')}}</label>
						<Dropdown v-model="police.tarifa" :options="tarifas" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" disabled></Dropdown>
					</div>
					<div class="p-col-3">
						<label>{{$t('Costo')}}</label> +{{kosten}}
						<InputText v-model="police.evaluacion.kosten" type="number" min="0" @change="Calcular()"/>
					</div>
					<div class="p-col-3">
						<label for="state">{{$t('Sin reclamación')}}</label>
						<div> {{ no_claim.noclaim }}%</div>
					</div>	
					<div class="p-col-3">
						<label for="state">{{$t('Descuento Especial')}}</label>
						<Dropdown v-model="police.evaluacion.descuento" :options="descuentos" optionLabel="nombre" :placeholder="$t('Seleccione...')" @change="Calcular()" ></Dropdown>
					</div>	
					<div class="p-col-3">
						<label for="state">{{$t('Duración')}}</label>
						<Dropdown v-model="police.duracion" :options="duracion" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" disabled></Dropdown>
					</div>	
					<div class="p-col-3">
						<label for="state">{{$t('Pagos')}}</label>
						<Dropdown v-model="police.cuotas" :options="cuotas" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" disabled></Dropdown>
					</div>	
					<div class="p-col-6">
						<label for="state">{{$t('Adicional')}}</label>
						<MultiSelect v-model="adicional" :options="adicionales" :optionLabel="$t('nombre')"  placeholder="...?" class="multiselect-custom"  @change="Calcular()">
							<template #option="slotProps">
								<div class="country-item country-item-value">
									<div>{{ slotProps.option.name }} - {{moneda(parseFloat(slotProps.option.valor).toFixed(2))}}</div>
								</div>
								</template>				
						</MultiSelect>
					</div>					
					<div class="p-col-12">
						<label>{{$t('Observación')}}</label>
						<Textarea v-model="police.evaluacion.comentario" rows="2"/>
					</div>
				</div>
            </div>
			
            <div class="card" v-if="calculo">
				<h4>{{$t('Resumen')}}</h4>
				<div class="p-field" >
					<div class="p-fluid p-grid" >
						<div class="p-col-12">
							<DataTable :value="resumen">
								<Column :header="$t('Descrpición')">
									<template #body="slotProps">
										{{slotProps.data[i18n.locale()]}}                              
									</template>
								</Column> 
								<Column :header="$t('Detalle Nuevo')">
									<template #body="slotProps">
										{{moneda(slotProps.data.nuevo)}}                              
									</template>
								</Column> 
								<Column :header="$t('Detalle Anterior')">
									<template #body="slotProps">
										{{moneda(slotProps.data.anterior)}}                              
									</template>
								</Column> 
								<Column :header="$t('Diferencia')">
									<template #body="slotProps">
										{{moneda(slotProps.data.nuevo-slotProps.data.anterior)}}                              
									</template>
								</Column> 
							</DataTable>
						</div>						
					</div>
				</div>
                <div class="p-fluid p-grid">
                    <div class="p-col-12 p-md-8">
						<h4><strong>{{$t('Diferencia')}}: {{moneda(((parseFloat(resumen[16].nuevo)-parseFloat(resumen[16].anterior))).toFixed(2))}}</strong></h4>
					</div>
                </div>
            </div>
			<div class="card">
				<div class="p-fluid p-grid">
					<div class="p-col-6 p-md-2" v-if="police.datos.cambio==false">
						<Button :disabled ="$store.state.loading" :label="$t('Procesar')" icon="pi pi-check" class="p-button-secondary" @click="Save" />
					</div>
					<div class="p-col-6 p-md-2">
						<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="displayeditar = false"/>
					</div>
				</div>
            </div>
        </div>

    </div>
</template>

<script>
import API from "../service/API";
const Consulta = new API('Configuracion','Cambio');
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import Token from "uuid-token-generator";
import PDF from "../service/PDF";


export default {
	components: {
    },
	data() {
		return {
			selectedCustomers: null,
			filters: {},
			i18n: null,
            poliza: [],
			url: null,
            buscar: null,
            polizas: [],
            displayview: false,
            police: {},
            estad: [],
            estados: [],
            displayeditar: false,
			noclaims: [],
			descuentos: [],
            duracion: [],
            cuotas: [],
            cuotas1: [],
            cuotas2: [],
            costo: null,
            years: [],
			poliza_tipos: [],
            tarifas: [],
            factor_limite: 0,
            recarga_edadveh: 0,
            recarga_edad: 0,
            recarga_volante: 0,
			adic_asientos: 0,
			premie: 0,
			kosten: 0,
			crs: 0,
			ob: 0,
			impuesto: 0,
            recargo: 0,
			imagen: null,
			mensaje: null,
			procesar: false,
			renovacion: false,
			codimg: null,
			tipos: [],
			marcavehiculo: [],
			modelos: [],
			img: null,
            size: '60vw',
            fecha: null,
			options: ['<- Left', 'Right->'],
			validez: [],
			anterior: null,
			adicionales: [],
			adicional: [],
			adic: 0,
			calculo: null,
			no_claim: null,
			resumen: [
				{es: 'Valor de Vehículo', en: 'Vehicle Value', anterior: null, nuevo: null, diferencia: null},
				{es: 'Deducible', en: 'Deductible', anterior: null, nuevo: null, diferencia: null},
				{es: 'Responsabilidad', en: 'Liability', anterior: null, nuevo: null, diferencia: null},
				
				{es: 'Recargo Asientos', en: 'Seats Surcharge', anterior: null, nuevo: null, diferencia: null},
				{es: 'Recargo Años del Vehículo', en: 'Vehicle Age Surcharge', anterior: null, nuevo: null, diferencia: null},
				{es: 'Recargo Edad Persona', en: 'Age Person Surcharge', anterior: null, nuevo: null, diferencia: null},
				{es: 'Recargo Volante', en: 'Steering Wheel Surcharge', anterior: null, nuevo: null, diferencia: null},
				
				{es: 'Prima Bruta WA', en: 'Gross premium WA', anterior: null, nuevo: null, diferencia: null},

				{es: 'Total WA', en: 'Total WA', anterior: null, nuevo: null, diferencia: null},
				{es: 'Sin reclamación WA', en: 'No Claim WA', anterior: null, nuevo: null, diferencia: null},
				{es: 'Cobertura Adicional', en: 'Additional Coverage', anterior: null, nuevo: null, diferencia: null},
				{es: 'Forma de pago', en: 'Payment Method', anterior: null, nuevo: null, diferencia: null},
				{es: 'Descuento Especial WA', en: 'Special Discount WA', anterior: null, nuevo: null, diferencia: null},
				{es: 'Premie WA', en: 'Premie WA', anterior: null, nuevo: null, diferencia: null},
				{es: 'Costo', en: 'Cost', anterior: null, nuevo: null, diferencia: null},
				{es: 'OB', en: 'OB', anterior: null, nuevo: null, diferencia: null},
				{es: 'Monto', en: 'Amount', anterior: null, nuevo: null, diferencia: null},
			],

        }
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
		const tokgen = new Token(256);
		this.img = tokgen.generate();
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
			this.estados = [];
            this.Dialogo = false;
			this.duracion = [];
			this.cuotas = [];
			this.cuotas1 = [];
            this.cuotas2 = [];
            Consulta.Ini().then(result => {
				//this.$store.state.error = result;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
				this.estados = result.consult.estados;
				this.noclaims = result.consult.noclaims;
				this.descuentos = result.consult.descuentos;
                this.duracion = result.consult.duracion;
				this.tipos = result.consult.tipos;
				this.marcavehiculo = result.consult.marcavehiculo;
				this.validez = result.consult.validez;
                this.cuotas = result.consult.cuotas;
                this.kosten = result.consult.valores.costo1;
				this.crs = result.consult.valores.costo2;
				this.impuesto = result.consult.valores.impuesto;
				this.adicionales = result.consult.adicionales;
				this.tarifas = result.consult.tarifas;
            }); 
        },
        VerPDF(data){
			Consulta.Procesar('VerPDF',{
                poliza: data.id,
				}).then(response => {
                    const doc = new PDF();
                    doc.Cambio(data,response.result);
                });           
        },
        Buscar(){
			this.calculo = null;
			this.displayeditar = false;
			this.poliza = [];
			Consulta.Procesar('Buscar',{
				id: this.buscar,
				}).then(response => {
					//this.$store.state.error = response.result.poliza;
                    if (response.result.poliza){
						this.anterior = response.result.poliza;
                        this.poliza = [response.result.poliza];
                        this.poliza_tipos = response.result.poliza_tipos;
						this.years = response.result.years;
                    }
                });
        },
		Editar(data) {
            this.factor_limite = 0;
            this.recarga_edadveh = 0;
            this.recarga_edad = 0;
            this.recarga_volante = 0;
			this.premie = 0;
			this.ob = 0;
            this.recargo = 0;
			this.police = {...data};
			this.police.tipovehiculo = this.tipos.filter(val => val.code == this.police.tipovehiculo && val.tipocliente == this.police.tipocliente && val.tipopoliza == this.police.tipopoliza                )[0];
			this.police.marca = this.marcavehiculo.filter(val => val.code == this.police.marca_id)[0];
			this.police.cardval = this.validez.filter(val => val.code == this.police.cardval)[0];
			this.police.veh_ano = this.years.filter(val => val.code == this.police.vehano)[0];
			this.police.cardemi = Consulta.fstring(this.police.cardemi);
			this.police.tarifa = this.tarifas.filter(val => val.code == this.police.evaluacion.poliza_valor)[0];
			this.police.evaluacion.tipopoliza = this.poliza_tipos.filter(val => val.code == this.police.evaluacion.tipopoliza)[0];
			this.police.duracion = this.duracion.filter(val => val.code == this.police.duracion)[0];
			this.police.cuotas = this.cuotas.filter(val => val.code == this.police.cuotas)[0];
			this.police.evaluacion.descuento = this.descuentos.filter(val => val.code == this.police.evaluacion.descuento)[0];
			this.police.anterior.descuento = this.descuentos.filter(val => val.code == this.police.anterior.descuento)[0];
			this.adicional = this.police.adicional;     
			if(data.datos.cambio){
				this.kosten = 0;
			}      
			if(this.police.marca){
				this.modelos = [];
				Consulta.Procesar('vermodelos',{
					marca: this.police.marca.code,
				}).then(response => {
					if (response.result){
						this.modelos = response.result;
						this.police.modelo = this.modelos.filter(val => val.code == this.police.modelo_id)[0];
						this.Calcular();
						this.displayeditar = true;
					}
				});
			}
		},
		vermodelos() {
			if(this.police.marca){
				this.police.modelo = null;
				this.calculo = null;
				this.police.veh_ano = null;
				this.years = [];
				this.modelos = [];
				//this.no_claim = null;
				Consulta.Procesar('vermodelos',{
					marca: this.police.marca.code,
				}).then(response => {
					if (response.result){
						this.modelos = response.result;
						let modelo = this.modelos.filter(val => val.code == this.police.modelo_id);
						if(modelo.length>0){
							this.police.modelo = modelo[0];
						}						
					}
				});
			}
        },
		veryears() {
			if(this.police.modelo.code>0){
				this.police.veh_ano = null;
				Consulta.Procesar('veryears',{
					modelo: this.police.modelo.code,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result){
						this.years = response.result;
					}
				});
			}
        },
		Adicional(){
			var adicional = 0;
			this.adicional.forEach(element => {
				adicional = adicional + parseFloat(element.valor);
			});
			this.adic = adicional;
		},
		Calcular() {
			this.Adicional();
			this.calculo = null;
			this.no_claim = null;
			Consulta.Procesar('Calcular',{
				poliza: this.police,
			}).then(response => {
				//this.$store.state.error = response;
				if(response){
					this.calculo = response.result.calculo;
					this.no_claim = response.result.no_claim;
					this.Tabla();
				}
            }); 
		},
		Tabla(){
			var ant = this.police.anterior2;
			var calc = this.calculo;
			let premie = ant.totalwa;
			this.resumen[0].nuevo = calc.catalogo_vehiculo;
			this.resumen[0].anterior = ant.valorcatalogo;

			this.resumen[1].nuevo = calc.deductible;
			this.resumen[1].anterior = this.police.evaluacion.deductible;

			this.resumen[2].nuevo = calc.tarifa.valor;
			this.resumen[2].anterior = this.police.responsabilidad;

			this.resumen[3].nuevo = calc.adic_asientos;
			this.resumen[3].anterior = ant.adic_asientos;

			this.resumen[4].nuevo = calc.recarga_veh;
			this.resumen[4].anterior = ant.recarga_veh;

			this.resumen[5].nuevo = calc.recarga_edad;
			this.resumen[5].anterior = ant.recarga_edad;

			this.resumen[6].nuevo = calc.recarga_volante;
			this.resumen[6].anterior = ant.recarga_volante;

			this.resumen[7].nuevo = calc.primabruta;
			this.resumen[7].anterior = ant.primabruta;

			this.resumen[8].nuevo = calc.subtotal;
			this.resumen[8].anterior = premie;

			//this.resumen[9].nuevo = calc.subtotal*(-1)*this.no_claim.factor;
			this.resumen[9].nuevo = calc.subtotal*this.no_claim.factor;
			//this.resumen[9].anterior = premie*this.police.evaluacion.no_claim/100;
			this.resumen[9].anterior =  premie*this.police.evaluacion.factor_noclaim;

			this.resumen[10].nuevo = this.adic*this.police.duracion.valor;
			this.resumen[10].anterior = ant.adicional*ant.duracion;

			this.resumen[11].nuevo = ((calc.subtotal*(1+this.no_claim.factor-this.police.evaluacion.descuento.valor))*parseFloat(this.police.cuotas.porcentaje/100));
			this.resumen[11].anterior = (premie*(1+this.police.evaluacion.factor_noclaim)+parseFloat(this.adic_asientos))*parseFloat(this.police.cuotas.porcentaje/100);

			this.resumen[12].nuevo = calc.subtotal*this.police.evaluacion.descuento.valor;
			this.resumen[12].anterior = premie*ant.descuento.valor;
		
			let Premie_WA = (((parseFloat(calc.subtotal*(1+this.no_claim.factor-this.police.evaluacion.descuento.valor)+this.adic))*(1+this.police.cuotas.porcentaje/100)*this.police.duracion.valor));
						
			this.resumen[13].nuevo = Premie_WA;
			this.resumen[13].anterior = parseFloat(ant.montofinal-ant.ob-ant.kosten);

			this.resumen[14].nuevo = parseFloat(this.police.evaluacion.kosten)+parseFloat(this.kosten);
			this.resumen[14].anterior = ant.kosten;

			this.resumen[15].nuevo = ((Premie_WA+parseFloat(this.police.evaluacion.kosten)+parseFloat(this.kosten))*(calc.impuesto));
			this.resumen[15].anterior = ant.ob;

			this.resumen[16].nuevo = ((Premie_WA+parseFloat(this.police.evaluacion.kosten)+parseFloat(this.kosten))*(1+calc.impuesto));
			this.resumen[16].anterior = ant.montofinal;

		},	
		myUploader() {
			Consulta.Procesar('RevFoto',{
				foto: this.img,
			}).then(response => {
				if(response.result){
					this.police.veh_adverso = this.img+'.jpg';
				} else {
					this.foto = response.result;
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
                const tokgen = new Token(256);
                this.img = tokgen.generate();
			});
		},
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
        Save(){
			if (
				this.police.tipovehiculo == null	||
				this.police.marca == null	||
				this.police.modelo == null	||
				this.police.veh_ano == null	||
				this.police.veh_compra == null	||	this.police.veh_compra == ''	||
				this.police.veh_chasis == null	||	this.police.veh_chasis == ''	||
				this.police.veh_placa == null	||	this.police.veh_placa == ''		||	
				this.police.veh_color == null	||	this.police.veh_color == ''		||
				this.police.cardemi == null		||	this.police.cardemi == ''		||
				this.police.cardval == null		||	this.police.cardval == ''		||
				this.police.veh_adverso == null	||	this.police.veh_adverso == ''	||
				this.police.evaluacion.poliza_valor == null	||
				this.police.evaluacion.noclaim == null	||	
				this.police.evaluacion.descuento == null
			) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error'), life: 10000});
			} else {
				let diferencia = (parseFloat(this.resumen[16].nuevo)-parseFloat(this.resumen[16].anterior));
				Consulta.Procesar('UPD',{
					police: this.police,
					anterior: this.anterior,
					calculo: this.calculo,
					noclaim: this.no_claim,
					adic: this.adic,
					adicional: this.adicional,
					diferencia: diferencia,
					crs: this.crs,
					ob: this.ob,
					kosten: this.kosten,
					renovacion: this.renovacion,
					leng: this.i18n.locale(),
					fecha: Consulta.fdate(this.police.cardemi),
					//broker_monto: parseFloat(totalnuebruta)*parseFloat(this.police.evaluacion.broker/100),
					}).then(response => {
						this.$store.state.error = response;	
						if (response.result){
							this.Buscar();
						}
					});

			}
        },
        moneda(number){
			if(!number){number = 0;}
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
        fdate(fecha){
            const [dia, mes, ano] = fecha.split("/");
            let cambiada = `${ano}-${mes}-${dia}`;
            return cambiada;
        },

	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
